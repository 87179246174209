import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { themesApi } from './services/themes';

const store = configureStore({
  reducer: {
    [themesApi.reducerPath]: themesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(themesApi.middleware),
});

setupListeners(store.dispatch);

export default store;