import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_NETLIFY_FUNCTIONS_BASE_URL || 'http://localhost:8888/.netlify/functions';

export const themesApi = createApi({
  reducerPath: 'themesApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  tagTypes: ['Themes', 'Theme'],
  endpoints: (builder) => ({
    getThemes: builder.query({
      query: ({ page, pageSize, filter, search }) => {
        const params = new URLSearchParams({ page, pageSize, filter });
        if (search) {
          params.append('search', search);
        }
        return `/getThemes?${params.toString()}`;
      },
      providesTags: ['Themes'],
    }),
    getThemeByName: builder.query({
      query: (name) => `/getThemeByName?name=${name}`,
      providesTags: (result, error, name) => [{ type: 'Theme', id: name }],
    }),
    updateLikes: builder.mutation({
      query: ({ id, like }) => ({
        url: '/updateLikes',
        method: 'POST',
        body: { id, like },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Themes' },
        { type: 'Theme', id: result?.name.replace(/\s+/g, '-').toLowerCase() },
      ],
    }),
    updateViewCount: builder.mutation({
      query: (id) => ({
        url: '/updateViewCount',
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Theme', id },
      ],
    }),
  }),
});

export const { useGetThemesQuery, useGetThemeByNameQuery, useUpdateLikesMutation, useUpdateViewCountMutation } = themesApi;
