import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useViewportSize } from '@mantine/hooks';
import { Container, Text, Alert, Button, Box, Title, Flex, ActionIcon, Select, Stack, ColorInput, SimpleGrid, Tooltip, Group, Textarea } from '@mantine/core';
import { Check, Clipboard, IconArrowLeft, IconEye, IconEyeOff } from 'tabler-icons-react';
import Chart from '../components/Chart';
import { useDocumentTitle } from '@mantine/hooks';
import themes from '../themes/index.json';
import { IconArrowBack, IconEyeglass, IconEyeglassOff } from '@tabler/icons-react';
import { Helmet } from 'react-helmet';

// Add this conversion function
const convertColor = (color, format) => {
  if (format === 'hex') {
    return color;
  } else if (format === 'rgb') {
    // Convert HEX to RGB
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    return `rgb(${r}, ${g}, ${b})`;
  }
  return color;
};

const ThemeDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const [theme, setTheme] = useState(null);
  const [format, setFormat] = useState("hex");
  const [showScales, setShowScales] = useState(true);
  const [chartKey, setChartKey] = useState(0); // Add this line
  const { width } = useViewportSize();
  const [initialDataPoints, setInitialDataPoints] = useState(175);
  const [newThemeInput, setNewThemeInput] = useState('');

  useEffect(() => {
    if (name === 'test-new-theme') {
      setTheme({
        name: 'Test New Theme',
        text_color: '#333333',
        ...JSON.parse(localStorage.getItem('testNewTheme') || '{}')
      });
    } else {
      const foundTheme = themes.find(t => t.name.replace(/\s+/g, '-').toLowerCase() === name);
      setTheme(foundTheme);
    }

    // Determine the number of initial data points based on screen width
    if (width < 768) {
      setInitialDataPoints(50);
    } else {
      setInitialDataPoints(175);
    }
  }, [name, width]);

  useEffect(() => {
    if (newThemeInput) {
      try {
        const parsedTheme = JSON.parse(newThemeInput);
        const newTheme = {
          name: 'Test New Theme',
          text_color: '#333333',
          ...parsedTheme
        };
        localStorage.setItem('testNewTheme', JSON.stringify(newTheme));
        setTheme(newTheme);
      } catch (error) {
        console.error("Invalid JSON input:", error);
        // You might want to show an error message to the user here
      }
    }
  }, [newThemeInput]);

  const title = theme?.name && `${theme?.name} | Chart Themes` || 'Chart Themes';
  useDocumentTitle(title);

  if (!theme) return <></>

  const candleColors = [
    { name: 'Bearish body', value: theme.bearish_color },
    { name: 'Bearish wick', value: theme.bearish_wick_color },
    { name: 'Bearish border', value: theme.bearish_border_color },
    { name: 'Bullish body', value: theme.bullish_color },
    { name: 'Bullish wick', value: theme.bullish_wick_color },
    { name: 'Bullish border', value: theme.bullish_border_color },
  ];

  const canvasColors = [
    { name: 'Background color', value: theme.background_color },
    { name: 'Foreground', value: theme.text_color },
    { name: 'Text color', value: theme.text_color },
  ];

  const handleGoBack = () => {
    navigate('/', { state: { fromThemeDetail: true } });
  };

  const toggleScales = () => {
    setShowScales(prev => !prev);
    setChartKey(prev => prev + 1); // Add this line
  };

  const pageTitle = `${theme.name} | Chart Themes`;
  const pageDescription = `Explore the ${theme.name} chart theme. Enhance your trading analysis with this professionally designed color scheme.`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={`chart theme, ${theme.name}, trading, analysis, color scheme`} />
        <link rel="canonical" href={`https://chartthemes.com/${name}`} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={`https://chartthemes.com/${name}`} />
        <meta property="og:type" content="article" />
        {name === 'test-new-theme' && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>
      <Container fluid px={0} pb={100}>
        <Box sx={{ height: '45vh', width: '100%', borderRadius: 12 }}>
          <Chart
            key={chartKey} // Add this line
            colors={{
              bullishColor: theme.bullish_color,
              bearishColor: theme.bearish_color,
              bullishWickColor: theme.bullish_wick_color,
              bearishWickColor: theme.bearish_wick_color,
              backgroundColor: theme.background_color,
              bullishBorderColor: theme.bullish_border_color,
              bearishBorderColor: theme.bearish_border_color,
              textColor: theme.text_color, // Add this line
            }}
            hideRightPriceScale={!showScales}
            hideBottomTimeScale={!showScales}
            initialDataPoints={initialDataPoints}
            renderNewBars={true}
          />
        </Box>

        <Flex justify='center' align='center' bg='gray.0' py='xl' gap={40}>
          <Group position="center">
            <Button size='lg' radius="lg" variant='subtle' leftIcon={<IconArrowBack size={18} stroke={1.75} />} onClick={handleGoBack}>Back to themes</Button>
          </Group>
          {/* <Button
            size='lg'
            radius="lg"
            variant='subtle'
            onClick={toggleScales}
          >
            {showScales ? 'Hide Scales' : 'Show Scales'}
          </Button> */}
        </Flex>

        {name === 'test-new-theme' && (
          <Box my={20} w='50vw' mx='auto'>
            <Title order={3} mb={10}>Test New Theme</Title>
            <Textarea
              placeholder="Paste your theme JSON here"
              value={newThemeInput}
              onChange={(event) => setNewThemeInput(event.currentTarget.value)}
              minRows={10}
              maxRows={20}
              autosize
              mb={10}
            />
          </Box>
        )}

        <Title order={1} align="center" fz={{ base: 45, xs: 70, md: 80 }} weight={600} lts={2} my="xl" pt={45} ff='Bebas Neue'>
          {theme.name}
        </Title>

        <Container mt={30} px={0}>
          <Flex justify='center' gap={50} w='100%'>
            <Select
              label="Pick your color format"
              placeholder="Pick one"
              data={[
                { value: 'hex', label: 'Hex' },
                { value: 'rgb', label: 'RGB' },
              ]}
              value={format}
              onChange={setFormat}
              size='lg'
              variant='filled'
              radius="lg"
              mb='lg'
            />
          </Flex>
        </Container>

        <Container mt={50}>
          <Title order={2} align="center" mb="xl">Candle colors</Title>
          <SimpleGrid
            cols={3}
            breakpoints={[
              { minWidth: 0, cols: 1 },
              { minWidth: 'md', cols: 3 },
            ]}
          >
            {candleColors.map(color => (
              <Stack key={color.name} sx={{ marginBottom: '1rem', position: 'relative' }} spacing='xs'>
                <Text fw={500} fz='xl'>{color.name}</Text>
                <ColorInput
                  size='lg'
                  value={convertColor(color.value, format)}
                  readOnly
                  format={format}
                  rightSection={
                    <Tooltip label="Copy" withArrow>
                      <ActionIcon onClick={() => navigator.clipboard.writeText(convertColor(color.value, format))}>
                        <Clipboard strokeWidth={1.5} size={22} />
                      </ActionIcon>
                    </Tooltip>
                  }
                />
              </Stack>
            ))}
          </SimpleGrid>
        </Container>

        <Container mt={50}>
          <Title order={2} align="center" mb="xl">General colors</Title>
          <SimpleGrid
            cols={3}
            breakpoints={[
              { minWidth: 0, cols: 1 },
              { minWidth: 'md', cols: 3 },
            ]}
          >
            {canvasColors.map(color => (
              <Stack key={color.name} sx={{ marginBottom: '1rem', position: 'relative' }} spacing='xs'>
                <Text fw={500} fz='xl'>{color.name}</Text>
                <ColorInput
                  size='lg'
                  value={convertColor(color.value, format)}
                  readOnly
                  format={format}
                  rightSection={
                    <Tooltip label="Copy" withArrow>
                      <ActionIcon onClick={() => navigator.clipboard.writeText(convertColor(color.value, format))}>
                        <Clipboard strokeWidth={1.5} size={22} />
                      </ActionIcon>
                    </Tooltip>
                  }
                />
              </Stack>
            ))}
          </SimpleGrid>
        </Container>
      </Container>
    </>
  );
};

export default ThemeDetail;