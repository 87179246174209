import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';

import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme: 'light',
          primaryColor: 'dark',
          fontFamily: '"DM Sans", sans-serif',
        }}
      >
        <App />
      </MantineProvider>
    </Provider>,
  </React.StrictMode>
);
