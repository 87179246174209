import React, { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";

const Chart = React.memo(
  ({
    colors,
    initialDataPoints = 150,
    renderNewBars = true,
    hideRightPriceScale = false,
    hideBottomTimeScale = false,
  }) => {
    const chartContainerRef = useRef();
    const [chart, setChart] = useState(null);
    const lastBarRef = useRef();
    const updatesPerCandle = 5;
    const updateCountRef = useRef(0);
    const intervalRef = useRef();

    useEffect(() => {
      const newChart = createChart(chartContainerRef.current, {
        layout: {
          background: {
            color: colors.backgroundColor,
          },
          textColor: colors.textColor,
        },
        grid: {
          vertLines: {
            color: "transparent",
          },
          horzLines: {
            color: "transparent",
          },
        },
        rightPriceScale: {
          color: colors.textColor,
          fontSize: 10,
          visible: !hideRightPriceScale,
          borderColor: colors.textColor,
        },
        timeScale: {
          borderColor: colors.textColor,
          rightOffset: typeof window !== 'undefined' && window.innerWidth <= 768 ? 5 : 10,
          barSpacing: 4,
          fontSize: 10,
          visible: !hideBottomTimeScale,
        },
        watermark: {
          text: "Liquidity Grab",
          fontFamily: '"Bebas Neue", sans-serif',
          fontSize: 75,
          color: "rgba(256, 256, 256, 0.1)",
        },
        crosshair: {
          mode: 0,
        },
      });

      setChart(newChart);

      const candleSeries = newChart.addCandlestickSeries({
        upColor: colors.bullishColor,
        downColor: colors.bearishColor,
        borderUpColor: colors.bullishBorderColor,
        borderDownColor: colors.bearishBorderColor,
        wickUpColor: colors.bullishWickColor,
        wickDownColor: colors.bearishWickColor,
        priceLineVisible: false,
      });

      // Initial Data Population
      for (let i = 0; i < initialDataPoints; i++) {
        const bar = nextBar();
        candleSeries.update(bar);
        lastBarRef.current = bar;
      }

      // Resize Event
      const resize = () => {
        const containerWidth = chartContainerRef.current.clientWidth;
        const containerHeight = chartContainerRef.current.clientHeight;
        newChart.applyOptions({
          width: containerWidth,
          height: containerHeight,
        });

        setTimeout(() => {
          newChart.timeScale().fitContent();
          newChart.timeScale().scrollToRealTime();
        }, 0);
      };

      window.addEventListener("resize", resize, false);
      resize();

      if (renderNewBars) {
        intervalRef.current = setInterval(() => {
          updateBar(candleSeries);
        }, 500);
      }

      return () => {
        window.removeEventListener("resize", resize, false);
        clearInterval(intervalRef.current);
        newChart.remove();
      };
    }, [colors, initialDataPoints, renderNewBars, hideRightPriceScale, hideBottomTimeScale]);

    useEffect(() => {
      if (chart) {
        chart.applyOptions({
          rightPriceScale: {
            visible: !hideRightPriceScale,
          },
          timeScale: {
            visible: !hideBottomTimeScale,
          },
        });
      }
    }, [chart, hideRightPriceScale, hideBottomTimeScale]);

    function nextBar() {
      if (!nextBar.date) nextBar.date = new Date(2020, 0, 1);
      if (!nextBar.bar)
        nextBar.bar = { open: 100, high: 104, low: 98, close: 103 };

      nextBar.date.setDate(nextBar.date.getDate() + 1);
      nextBar.bar.time = {
        year: nextBar.date.getFullYear(),
        month: nextBar.date.getMonth() + 1,
        day: nextBar.date.getDate(),
      };

      let old_price = nextBar.bar.close;
      let volatility = 0.05; // Reduced volatility
      let rnd = Math.random();
      let change_percent = 2 * volatility * rnd;

      if (change_percent > volatility) change_percent -= 2 * volatility;

      let change_amount = old_price * change_percent;
      nextBar.bar.open = nextBar.bar.close;
      nextBar.bar.close = old_price + change_amount;
      nextBar.bar.high =
        Math.max(nextBar.bar.open, nextBar.bar.close) +
        Math.abs(change_amount) * Math.random();
      nextBar.bar.low =
        Math.min(nextBar.bar.open, nextBar.bar.close) -
        Math.abs(change_amount) * Math.random();
      nextBar.bar.value = Math.random() * 100;

      return nextBar.bar;
    }

    function updateBar(candleSeries) {
      let lastBar = lastBarRef.current;
      const volatility = 0.02; // Reduced volatility for updates
      const changePercent = (Math.random() * 2 - 1) * volatility;
      const changeAmount = lastBar.close * changePercent;

      const newBar = {
        ...lastBar,
        high: Math.max(lastBar.high, lastBar.close + changeAmount),
        low: Math.min(lastBar.low, lastBar.close + changeAmount),
        close: lastBar.close + changeAmount,
      };

      lastBarRef.current = newBar;
      candleSeries.update(newBar);

      updateCountRef.current += 1;

      // After updating the same candle 5 times, stay on the last update for 500ms before printing the next bar
      if (updateCountRef.current >= updatesPerCandle) {
        clearInterval(intervalRef.current);
        updateCountRef.current = 0;
        setTimeout(() => {
          const nextBar = {
            time: {
              year: lastBar.time.year,
              month: lastBar.time.month,
              day: lastBar.time.day + 1,
            },
            open: newBar.close,
            high: newBar.close,
            low: newBar.close,
            close: newBar.close,
          };
          lastBarRef.current = nextBar;
          candleSeries.update(nextBar);
          intervalRef.current = setInterval(() => {
            updateBar(candleSeries);
          }, 500);
        }, 500); // Stay on the last update for an additional 500ms before printing the next bar
      }
    }

    return (
      <div
        ref={chartContainerRef}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          pointerEvents: typeof window !== 'undefined' && window.innerWidth <= 768 ? 'none' : 'auto',
        }}
      />
    );
  }
);

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.colors.bullishColor === nextProps.colors.bullishColor &&
    prevProps.colors.bearishColor === nextProps.colors.bearishColor &&
    prevProps.colors.bullishWickColor === nextProps.colors.bullishWickColor &&
    prevProps.colors.bearishWickColor === nextProps.colors.bearishWickColor &&
    prevProps.colors.backgroundColor === nextProps.colors.backgroundColor &&
    prevProps.colors.bullishBorderColor === nextProps.colors.bullishBorderColor &&
    prevProps.colors.bearishBorderColor === nextProps.colors.bearishBorderColor &&
    prevProps.initialDataPoints === nextProps.initialDataPoints &&
    prevProps.renderNewBars === nextProps.renderNewBars &&
    prevProps.hideRightPriceScale === nextProps.hideRightPriceScale &&
    prevProps.hideBottomTimeScale === nextProps.hideBottomTimeScale
  );
};

export default React.memo(Chart, areEqual);
