import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, Card, Text, Container, Grid, Col, Box, Flex, Title, Chip, Group, Stack, Center, Collapse, ActionIcon } from '@mantine/core';
import { useDocumentTitle, useMediaQuery } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp, IconBrandTwitter, IconBrandTwitterFilled } from '@tabler/icons-react';
import Chart from '../components/Chart';
import themes from '../themes/index.json';

const Home = () => {
  useDocumentTitle('Chart Themes | Trade With Clarity');
  const [selectedColorTypes, setSelectedColorTypes] = useState(() =>
    JSON.parse(localStorage.getItem('selectedColorTypes') || '[]')
  );
  const [selectedColors, setSelectedColors] = useState(() =>
    JSON.parse(localStorage.getItem('selectedColors') || '[]')
  );
  const [filteredThemes, setFilteredThemes] = useState(themes);

  const priorityColorTypes = ["Light", "Dark", "Neutral", "Vibrant", "Monochrome"];
  const otherColors = [...new Set(themes.flatMap(theme =>
    theme.tags.filter(tag => !priorityColorTypes.map(t => t.toLowerCase()).includes(tag.toLowerCase()))
  ))];

  const allTags = [...priorityColorTypes, ...otherColors];

  useEffect(() => {
    const filtered = themes.filter(theme => {
      const colorTypeMatch = selectedColorTypes.length === 0 || selectedColorTypes.some(type => theme.tags.includes(type.toLowerCase()));
      const colorMatch = selectedColors.length === 0 || selectedColors.every(color => theme.tags.includes(color.toLowerCase()));
      return colorTypeMatch && colorMatch;
    });
    setFilteredThemes(filtered);
    localStorage.setItem('selectedColorTypes', JSON.stringify(selectedColorTypes));
    localStorage.setItem('selectedColors', JSON.stringify(selectedColors));
  }, [selectedColorTypes, selectedColors]);

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition));
      localStorage.removeItem('scrollPosition');
    }

    const handleScroll = () => {
      localStorage.setItem('scrollPosition', window.pageYOffset.toString());
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [showAllChips, setShowAllChips] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const visibleTags = isMobile ? allTags.slice(0, 5) : allTags;
  const hiddenTags = isMobile ? allTags.slice(5) : [];

  const appliedHiddenFilters = hiddenTags.filter(tag =>
    selectedColorTypes.includes(tag) || selectedColors.includes(tag)
  ).length;

  return (
    <>
      <Helmet>
        <title>Chart Themes | Trade With Clarity</title>
        <meta name="description" content="Discover expertly crafted chart themes for trading. Enhance your analysis with clear, visually appealing color schemes." />
        <meta name="keywords" content="chart themes, trading, analysis, color schemes, financial charts" />
        <link rel="canonical" href="https://chartthemes.com" />
        <meta property="og:title" content="Chart Themes | Trade With Clarity" />
        <meta property="og:description" content="Discover expertly crafted chart themes for trading. Enhance your analysis with clear, visually appealing color schemes." />
        <meta property="og:url" content="https://chartthemes.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Container size={2500} pb={20} px={{ base: 'xs', sm: 'xl' }}>
        <Box pb='md' pt={40} sx={{ position: 'relative' }}>
          <ActionIcon
            component="a"
            href="https://x.com/chartthemes"
            target="_blank"
            rel="noopener noreferrer"
            sx={(theme) => ({
              position: 'absolute',
              top: theme.spacing.md,
              right: theme.spacing.xl, // Changed from md to xl for more right positioning
              color: theme.colorScheme === 'dark' ? theme.colors.blue[2] : theme.colors.blue[3], // Lighter blue color
            })}
            size="lg"
            radius="xl"
            variant="subtle"
            mr={{ base: 'sm', sm: -35 }}
            pt='sm'
          >
            <IconBrandTwitterFilled size={44} />
          </ActionIcon>
          <Stack spacing={0} pb='xl' align='center' mt='md'>
            <Title order={1} align="center" fz={{ base: 40, sm: 48, md: 64, lg: 80 }} weight={600} lts={2} my="md" ff='Bebas Neue'>
              Trade with Clarity
            </Title>
            <Text mt='-md' fz={{ base: 16, sm: 20 }} maw={500} ta='center' px={{ base: 'md', sm: 0 }}>
              <Text span fw={700} gradient={{ from: 'dark.9', to: 'orange.9', deg: 95 }} variant='gradient'>Discover your next trading edge</Text>—ditch outdated colors with our expertly crafted themes and achieve unmatched clarity in your analysis.
            </Text>
          </Stack>
        </Box>

        <Box
          sx={(theme) => ({
            position: 'sticky',
            top: 0,
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            zIndex: 1000,
            padding: theme.spacing.md,
            borderBottom: `1px solid ${
              theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
            }`,
          })}
        >
          <Flex justify='center' mt='md' gap='md' mb='lg' direction='column'>
            <Chip.Group multiple value={[...selectedColorTypes, ...selectedColors]} onChange={(value) => {
              setSelectedColorTypes(value.filter(v => priorityColorTypes.includes(v)));
              setSelectedColors(value.filter(v => otherColors.includes(v)));
            }}>
              <Group spacing={8} align="center">
                {visibleTags.map(tag => (
                  <Chip key={tag} value={tag} size={isMobile ? "sm" : "lg"} mb={4}>
                    {tag.charAt(0).toUpperCase() + tag.slice(1)}
                  </Chip>
                ))}
                {isMobile && hiddenTags.length > 0 && (
                  <Button
                    variant="subtle"
                    size="sm"
                    onClick={() => setShowAllChips(!showAllChips)}
                    rightIcon={showAllChips ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
                  >
                    {showAllChips
                      ? 'Hide filters'
                      : `Show filters${appliedHiddenFilters > 0 ? ` (${appliedHiddenFilters})` : ''}`
                    }
                  </Button>
                )}
              </Group>
              {isMobile && (
                <Collapse in={showAllChips}>
                  <Group spacing={8} align="center" mt={8}>
                    {hiddenTags.map(tag => (
                      <Chip key={tag} value={tag} size="sm" mb={4}>
                        {tag.charAt(0).toUpperCase() + tag.slice(1)}
                      </Chip>
                    ))}
                  </Group>
                </Collapse>
              )}
            </Chip.Group>
          </Flex>
        </Box>

        {filteredThemes.length > 0 ? (
          <Grid gutter={{ base: 'lg', sm: 'xl', md: 'xl', lg: 40 }} mt={35}>
            {filteredThemes.map(theme => (
              <Col span={12} sm={6} md={4} key={theme.id} pb="lg" px={{ base: 'sm', sm: 'md' }}>
                <Card
                  py={{ base: 'sm', md: 'md' }}
                  px={{ base: 'md', sm: 'lg', md: 'xl' }}
                  sx={{
                    borderRadius: 16,
                    height: '100%',
                  }}
                >
                  <Flex mt={10} justify="space-between" align='center' mb={20} direction='row'>
                    <Title mt={3} fz={{ base: 28, sm: 30, md: 34 }} weight={500} ff='Bebas Neue'>{theme.name}</Title>
                    <Button
                      size={isMobile ? 'sm' : 'md'}
                      radius="lg"
                      px={isMobile ? 'xs' : 'md'}
                      component={Link}
                      to={`/${theme.name.replace(/\s+/g, '-').toLowerCase()}`}
                      color='gray.9'
                    >
                      Copy colors
                    </Button>
                  </Flex>
                  <Box
                    sx={{
                      height: isMobile ? 200 : 350,
                      width: 'auto',
                      borderRadius: 12,
                      overflow: 'hidden',
                      '&:hover': { cursor: isMobile ? 'default' : 'pointer' },
                    }}
                    mb='md'
                  >
                    <Chart
                      colors={{
                        bullishColor: theme.bullish_color,
                        bearishColor: theme.bearish_color,
                        bullishWickColor: theme.bullish_wick_color,
                        bearishWickColor: theme.bearish_wick_color,
                        backgroundColor: theme.background_color,
                        bullishBorderColor: theme.bullish_border_color,
                        bearishBorderColor: theme.bearish_border_color,
                        textColor: theme.text_color,
                      }}
                      initialDataPoints={45}
                      renderNewBars
                      disableInteractions={isMobile}
                      hideRightPriceScale={false}
                      hideBottomTimeScale={false}
                    />
                  </Box>
                </Card>
              </Col>
            ))}
          </Grid>
        ) : (
          <Center mt={100}>
            <Stack align="center" spacing="md">
              <Title order={2}>No matching themes found</Title>
              <Text size="lg" color="dimmed">
                Try adjusting your filters or explore different color combinations.
              </Text>
              <Button
                radius="lg"
                onClick={() => {
                  setSelectedColorTypes([]);
                  setSelectedColors([]);
                }}
                size="lg"
                mt="md"
              >
                Clear all filters
              </Button>
            </Stack>
          </Center>
        )}
      </Container>
    </>
  );
};

export default Home;
