import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import ThemeDetail from './pages/ThemeDetail';

const ScrollToTop = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.fromThemeDetail) {
      const savedScrollPosition = localStorage.getItem('scrollPosition');
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition));
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return children;
};

const App = () => (
  <Router>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
    </Helmet>
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:name" element={<ThemeDetail />} />
      </Routes>
    </ScrollToTop>
  </Router>
);

export default App;
